const _inherit = "inherit"
const _current = "currentColor"
const _transparent = "transparent"
const _black = "#000"
const _white = "#fff"
const _slate = {"50":"#f8fafc","100":"#f1f5f9","200":"#e2e8f0","300":"#cbd5e1","400":"#94a3b8","500":"#64748b","600":"#475569","700":"#334155","800":"#1e293b","900":"#0f172a","950":"#020617"}
const _gray = {"50":"rgb(var(--color-gray-50) / <alpha-value>)","100":"rgb(var(--color-gray-100) / <alpha-value>)","200":"rgb(var(--color-gray-200) / <alpha-value>)","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","950":"rgb(var(--color-gray-950) / <alpha-value>)"}
const _zinc = {"50":"#fafafa","100":"#f4f4f5","200":"#e4e4e7","300":"#d4d4d8","400":"#a1a1aa","500":"#71717a","600":"#52525b","700":"#3f3f46","800":"#27272a","900":"#18181b","950":"#09090b"}
const _neutral = {"50":"#fafafa","100":"#FFFFFF","200":"#F2F2F1","300":"#EDEDEA","400":"#E1E1DC","500":"#D5D5CE","600":"#C7C7BE","700":"#95958D","800":"#7C7C77","900":"#62625D","950":"#0a0a0a","debug":"red"}
const _stone = {"50":"#fafaf9","100":"#f5f5f4","200":"#e7e5e4","300":"#d6d3d1","400":"#a8a29e","500":"#78716c","600":"#57534e","700":"#44403c","800":"#292524","900":"#1c1917","950":"#0c0a09"}
const _red = {"50":"#fef2f2","100":"#fee2e2","200":"#fecaca","300":"#fca5a5","400":"#f87171","500":"#ef4444","600":"#dc2626","700":"#b91c1c","800":"#991b1b","900":"#7f1d1d","950":"#450a0a"}
const _orange = {"50":"#fff7ed","100":"#ffedd5","200":"#fed7aa","300":"#fdba74","400":"#fb923c","500":"#f97316","600":"#ea580c","700":"#c2410c","800":"#9a3412","900":"#7c2d12","950":"#431407"}
const _amber = {"50":"#fffbeb","100":"#fef3c7","200":"#fde68a","300":"#fcd34d","400":"#fbbf24","500":"#f59e0b","600":"#d97706","700":"#b45309","800":"#92400e","900":"#78350f","950":"#451a03"}
const _yellow = {"50":"#fefce8","100":"#fef9c3","200":"#fef08a","300":"#fde047","400":"#facc15","500":"#eab308","600":"#ca8a04","700":"#a16207","800":"#854d0e","900":"#713f12","950":"#422006"}
const _lime = {"50":"#f7fee7","100":"#ecfccb","200":"#d9f99d","300":"#bef264","400":"#a3e635","500":"#84cc16","600":"#65a30d","700":"#4d7c0f","800":"#3f6212","900":"#365314","950":"#1a2e05"}
const _green = {"50":"#f0fdf4","100":"#dcfce7","200":"#bbf7d0","300":"#86efac","400":"#4ade80","500":"#22c55e","600":"#16a34a","700":"#15803d","800":"#166534","900":"#14532d","950":"#052e16"}
const _emerald = {"50":"#ecfdf5","100":"#d1fae5","200":"#a7f3d0","300":"#6ee7b7","400":"#34d399","500":"#10b981","600":"#059669","700":"#047857","800":"#065f46","900":"#064e3b","950":"#022c22"}
const _teal = {"50":"#f0fdfa","100":"#ccfbf1","200":"#99f6e4","300":"#5eead4","400":"#2dd4bf","500":"#14b8a6","600":"#0d9488","700":"#0f766e","800":"#115e59","900":"#134e4a","950":"#042f2e"}
const _cyan = {"50":"#ecfeff","100":"#cffafe","200":"#a5f3fc","300":"#67e8f9","400":"#22d3ee","500":"#06b6d4","600":"#0891b2","700":"#0e7490","800":"#155e75","900":"#164e63","950":"#083344"}
const _sky = {"50":"#f0f9ff","100":"#e0f2fe","200":"#bae6fd","300":"#7dd3fc","400":"#38bdf8","500":"#0ea5e9","600":"#0284c7","700":"#0369a1","800":"#075985","900":"#0c4a6e","950":"#082f49"}
const _blue = {"50":"#eff6ff","100":"#dbeafe","200":"#bfdbfe","300":"#93c5fd","400":"#60a5fa","500":"#3b82f6","600":"#2563eb","700":"#1d4ed8","800":"#1e40af","900":"#1e3a8a","950":"#172554"}
const _indigo = {"50":"#eef2ff","100":"#e0e7ff","200":"#c7d2fe","300":"#a5b4fc","400":"#818cf8","500":"#6366f1","600":"#4f46e5","700":"#4338ca","800":"#3730a3","900":"#312e81","950":"#1e1b4b"}
const _violet = {"50":"#f5f3ff","100":"#ede9fe","200":"#ddd6fe","300":"#c4b5fd","400":"#a78bfa","500":"#8b5cf6","600":"#7c3aed","700":"#6d28d9","800":"#5b21b6","900":"#4c1d95","950":"#2e1065"}
const _purple = {"50":"#faf5ff","100":"#f3e8ff","200":"#e9d5ff","300":"#d8b4fe","400":"#c084fc","500":"#a855f7","600":"#9333ea","700":"#7e22ce","800":"#6b21a8","900":"#581c87","950":"#3b0764"}
const _fuchsia = {"50":"#fdf4ff","100":"#fae8ff","200":"#f5d0fe","300":"#f0abfc","400":"#e879f9","500":"#d946ef","600":"#c026d3","700":"#a21caf","800":"#86198f","900":"#701a75","950":"#4a044e"}
const _pink = {"50":"#fdf2f8","100":"#fce7f3","200":"#fbcfe8","300":"#f9a8d4","400":"#f472b6","500":"#ec4899","600":"#db2777","700":"#be185d","800":"#9d174d","900":"#831843","950":"#500724"}
const _rose = {"50":"#fff1f2","100":"#ffe4e6","200":"#fecdd3","300":"#fda4af","400":"#fb7185","500":"#f43f5e","600":"#e11d48","700":"#be123c","800":"#9f1239","900":"#881337","950":"#4c0519"}
const _placeholder = "#95958D"
const _badge = {"background":{"neutral":"#EDEDEA"},"border":{"default":"#C7C7BE","neutral":"#C7C7BE"}}
const _border = {"dark":"#95958D","default":"#C7C7BE"}
const _button = {"border":{"outline":"#95958D"}}
const _text = {"body":"#3F4D62","negative":"#FFFFFF","primary":"#000F21","placeholder":"#95958D","disabled":"#4343294D"}
const _brand = {"50":"#EBF5FF","100":"#2D73FF","200":"#2362DB","300":"#1A54C4","400":"#1045AD","500":"#06348D","600":"#05296D","700":"#052157","800":"#021842","900":"#001221","DEFAULT":"#003e87","debug":"#00FFFF"}
const _forgd = {"100":"#ebebeb","200":"#d2d2d2","300":"#cccccc","400":"#a8a196","lightGreen":"#c0d2a3","accent":{"100":"#2DF3FF","200":"#2362DB","300":"#1A54C4","400":"#1045AD","500":"#06348D","600":"#05296D","700":"#052157","800":"#021842","900":"#000F21"},"neutral":{"100":"#FFFFFF","200":"#F2F2F1","300":"#EDEDEA","400":"#E1E1DC","500":"#D5D5CE","600":"#C7C7BE","700":"#95958D","800":"#7C7C77","900":"#62625D"},"primary":{"50":"#EBF5FF","100":"#2D73FF","200":"#2362DB","300":"#1A54C4","400":"#1045AD","500":"#06348D","600":"#05296D","700":"#052157","800":"#021842","900":"#001221","DEFAULT":"#003e87"},"gray":{"50":"#F9FAFB","100":"#F3F4F6","200":"#E5E7EB","300":"#D1D5DB","350":"#C7C7BE","400":"#9CA3AF","450":"#95958D","500":"#606A7F","600":"#3F4D62","700":"#2C3B52","800":"#1A293B","900":"#111928"},"bgd":{"100":"#FFFFFF","200":"#F2F2F1","300":"#EDEDEA","400":"#E1E1DC","500":"#D5D5CE","600":"#C7C7BE","700":"#AEAEA4","800":"#021842","900":"#001221"},"blue":{"50":"#EBF5FF","100":"#E1EFFE","200":"#C3DDFD","300":"#A4CAFE","400":"#76A9FA","500":"#3F83F8","600":"#1C64F2","700":"#1A56DB","800":"#1E429F","900":"#233876"},"red":{"50":"#FDF2F2","100":"#F3E5E5","200":"#FBD5D5","300":"#F8B4B4","400":"#F98080","500":"#F05252","600":"#E02424","700":"#C81E1E","800":"#9B1C1C","900":"#771D1D"},"yellow":{"50":"#FDFDEA","100":"#EAE8D1","200":"#DCD8A7","300":"#D5CD7D","400":"#C4BB5F","500":"#A79D38","600":"#918619","700":"#7D730F","800":"#675E06","900":"#544D00"},"green":{"50":"#F3FAF7","100":"#DFE9E3","200":"#C6DAD3","300":"#9AD3BC","400":"#4ABF93","500":"#0E9F6E","600":"#057A55","700":"#046C4E","800":"#03543F","900":"#014737"},"social":{"facebook":"#35518D","twitter":"#1DA1F2","google":"#4284F4","dribbble":"#EA4C89","github":"#1B1F23"},"chart":{"0":"#041524","1":"#BDA46C","2":"#409090","3":"#E8BFD8","4":"#CD5F5F","5":"#92C2D1","6":"#2476A4","7":"#DF9E52","8":"#C0D2A3","9":"#66A680","10":"#5D6DA5"}}
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _cool = {"50":"#f9fafb","100":"#f3f4f6","200":"#e5e7eb","300":"#d1d5db","400":"#9ca3af","500":"#6b7280","600":"#4b5563","700":"#374151","800":"#1f2937","900":"#111827","950":"#030712"}
const config = { "inherit": _inherit, "current": _current, "transparent": _transparent, "black": _black, "white": _white, "slate": _slate, "gray": _gray, "zinc": _zinc, "neutral": _neutral, "stone": _stone, "red": _red, "orange": _orange, "amber": _amber, "yellow": _yellow, "lime": _lime, "green": _green, "emerald": _emerald, "teal": _teal, "cyan": _cyan, "sky": _sky, "blue": _blue, "indigo": _indigo, "violet": _violet, "purple": _purple, "fuchsia": _fuchsia, "pink": _pink, "rose": _rose, "placeholder": _placeholder, "badge": _badge, "border": _border, "button": _button, "text": _text, "brand": _brand, "forgd": _forgd, "primary": _primary, "cool": _cool, "background-accent": "#1A54C4", "background-dark": "#000F21",  }
export { config as default, _inherit, _current, _transparent, _black, _white, _slate, _gray, _zinc, _neutral, _stone, _red, _orange, _amber, _yellow, _lime, _green, _emerald, _teal, _cyan, _sky, _blue, _indigo, _violet, _purple, _fuchsia, _pink, _rose, _placeholder, _badge, _border, _button, _text, _brand, _forgd, _primary, _cool }