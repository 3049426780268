import type { paths } from '@forgd/contract/openapi'
import type { PostHog } from 'posthog-js'
import { defu } from 'defu'

// workaround for issue importing tracing type from contract
type TracingEventType = NonNullable<paths['/events']['post']['requestBody']>['content']['application/json'][number]

const MaxQueueSize = 10

const logger = useDevLogger('tracer')

let posthogInstance: PostHog | undefined

async function initPosthog() {
  if (import.meta.dev) {
    logger.debug('posthog is disabled in dev')
    // Create a minimal mock that implements only the methods we use
    return {
      capture: () => {},
      identify: () => {},
      init: () => {},
    } as unknown as PostHog
  }

  if (posthogInstance) {
    return posthogInstance
  }

  const config = useRuntimeConfig()
  const posthog = await import('posthog-js').then(module => module.default)
  posthogInstance = posthog.init(config.public.posthog.publicKey, {
    api_host: config.public.posthog.host || 'https://us.i.posthog.com',
    capture_pageview: false,
    loaded: (ph) => {
      if (import.meta.dev) {
        ph.debug()
      }
    },
  })
  return posthogInstance
}

function createTracer(options?: { defaultEventData?: TracingEventType['eventData'] }) {
  const defaults = defu(options?.defaultEventData || {}, {
    userAgent: navigator.userAgent,
  })
  const auth = useAuth()
  const route = useRoute()
  const ph = initPosthog()

  function thenPh(fn: ((ph: PostHog) => void)) {
    ph.then((ph) => {
      ph && fn(ph)
    })
  }

  const queue: TracingEventType[] = []
  let flushQueued: boolean = false

  async function flush() {
    const body = [...queue.splice(0, MaxQueueSize)]
      .map(event => defu(event, { eventData: defaults }))
    if (import.meta.dev) {
      logger.debug('Flushing queue', body)
    }
    else if (body.length) {
      await useQuery('/events', { method: 'post', params: { body } })
    }
    flushQueued = false
    if (queue.length > 0) {
      queueFlush()
    }
  }

  function queueFlush() {
    if (!flushQueued) {
      flushQueued = true
      onNuxtReady(() => {
        flush()
      })
    }
  }

  return {
    pageView(data?: TracingEventType['eventData']) {
      if (!auth.loggedIn) {
        return
      }
      const payload = defu(
        { eventData: data },
        { eventData: { path: route.fullPath }, projectId: auth.project?.id, eventType: 'page_view' },
      )
      logger.debug('Page view', payload)
      queue.push(payload)
      queueFlush()

      // Send to PostHog
      thenPh(({ capture }) => capture('$pageview', {
        ...payload.eventData,
        project_id: auth.project?.id,
      }))
    },
    custom(eventType: string, data: TracingEventType['eventData']) {
      if (!auth.loggedIn) {
        return
      }
      const payload = defu(
        { eventData: data },
        { eventData: { path: route.fullPath }, projectId: auth.project?.id, eventType },
      )
      logger.debug(`Custom: ${eventType}`, payload)
      queue.push(payload)
      queueFlush()

      // Send to PostHog
      thenPh(({ capture }) => capture(eventType, {
        ...payload.eventData,
        project_id: auth.project?.id,
      }))
    },
    identify(id: string, { email }: { email?: string }) {
      logger.debug('identify', { id })
      thenPh(({ identify }) => identify(id, { email }))
    },
  }
}

type Tracer = ReturnType<typeof createTracer>

export function useTracer(): Tracer {
  const nuxtApp = useNuxtApp()
  // singleton
  if (!nuxtApp._tracer) {
    nuxtApp._tracer = createTracer()
  }
  return nuxtApp._tracer as Tracer
}
