export const checksums = {
  "pages": "nfSyjq2pVM",
  "snippets": "jXm8EJPKBf"
}

export const tables = {
  "pages": "_content_pages",
  "snippets": "_content_snippets",
  "info": "_content_info"
}

export default {
  "pages": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "introduction": "boolean",
      "headline": "string",
      "icon": "string",
      "wistiaId": "string"
    }
  },
  "snippets": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json"
    }
  },
  "info": {
    "fields": {}
  }
}