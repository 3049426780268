import unhead_cwlDfSVHkW from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_better-sqlite3@11.8.1_db0@0.2.3_better-_mofyrrfsvf6f3unns4q2szcs34/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_V1YhWU7Nym from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_better-sqlite3@11.8.1_db0@0.2.3_better-_mofyrrfsvf6f3unns4q2szcs34/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_0Xg0t7ySbj from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+supabase@1.4.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_G0CYUmLYVb from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_better-sqlite3@11.8.1_db0@0.2.3_better-_mofyrrfsvf6f3unns4q2szcs34/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_qsPq6wjEzs from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_better-sqlite3@11.8.1_db0@0.2.3_better-_mofyrrfsvf6f3unns4q2szcs34/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ABozWNEZRh from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_better-sqlite3@11.8.1_db0@0.2.3_better-_mofyrrfsvf6f3unns4q2szcs34/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ogxUqBWoKe from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_better-sqlite3@11.8.1_db0@0.2.3_better-_mofyrrfsvf6f3unns4q2szcs34/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import preview_client_9OAtz4E9yR from "/opt/render/project/src/node_modules/.pnpm/@nuxt+content@3.1.0_drizzle-orm@0.39.1_@opentelemetry+api@1.9.0_@types+pg@8.11.11_@types+reac_xlkyikt7gpvsy2t6dv2j4h3xzi/node_modules/@nuxt/content/dist/runtime/plugins/preview.client.js";
import plugin_vue3_L5JiatYosJ from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.32.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/ui/.nuxt/components.plugin.mjs";
import prefetch_client_h2zooGc5dE from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_better-sqlite3@11.8.1_db0@0.2.3_better-_mofyrrfsvf6f3unns4q2szcs34/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/opt/render/project/src/apps/ui/.nuxt/floating-vue.mjs";
import plugin_XIEimTskX0 from "/opt/render/project/src/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.32.1_typescript@5_l6zqgodxro2smzxxz4jwza6bwq/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import slideovers_fuH7Q3JnJM from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.6_axios@1.7.9_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.32.1_v_se7anyiv4skzdq6glhmph4qjgq/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_gmZEJvAP0G from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.6_axios@1.7.9_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.32.1_v_se7anyiv4skzdq6glhmph4qjgq/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_NAikSmy9V3 from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.6_axios@1.7.9_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.32.1_v_se7anyiv4skzdq6glhmph4qjgq/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_2hKdcUlLdz from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.32.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_FGtNtp8axF from "/opt/render/project/src/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.13.1_jiti@2.4.2_sas_2keugaj72ys6kozcktuigqhm6i/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import search_aUu6HJpKV6 from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/academy/plugins/search.ts";
import lottie_AzpYEGWRZG from "/opt/render/project/src/libs/nuxt-forgd-layer-core/plugins/lottie.ts";
import sentry_client_3lKG8ZOviM from "/opt/render/project/src/libs/nuxt-forgd-layer-core/plugins/sentry.client.ts";
import tracing_client_csePbkLi2Z from "/opt/render/project/src/libs/nuxt-forgd-layer-core/plugins/tracing.client.ts";
import access_client_4oVQ9ZBHtd from "/opt/render/project/src/apps/ui/plugins/access.client.ts";
export default [
  unhead_cwlDfSVHkW,
  router_V1YhWU7Nym,
  supabase_client_0Xg0t7ySbj,
  payload_client_G0CYUmLYVb,
  navigation_repaint_client_qsPq6wjEzs,
  check_outdated_build_client_ABozWNEZRh,
  chunk_reload_client_ogxUqBWoKe,
  preview_client_9OAtz4E9yR,
  plugin_vue3_L5JiatYosJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_h2zooGc5dE,
  floating_vue_EIcJ7xiw0h,
  plugin_XIEimTskX0,
  slideovers_fuH7Q3JnJM,
  modals_gmZEJvAP0G,
  colors_NAikSmy9V3,
  plugin_client_2hKdcUlLdz,
  plugin_FGtNtp8axF,
  search_aUu6HJpKV6,
  lottie_AzpYEGWRZG,
  sentry_client_3lKG8ZOviM,
  tracing_client_csePbkLi2Z,
  access_client_4oVQ9ZBHtd
]