import { defineAsyncComponent } from 'vue'
export default {
  dashboard: defineAsyncComponent(() => import("/opt/render/project/src/apps/ui/layouts/dashboard.vue").then(m => m.default || m)),
  "core-dashboard": defineAsyncComponent(() => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layouts/core-dashboard.vue").then(m => m.default || m)),
  auth: defineAsyncComponent(() => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/layouts/auth.vue").then(m => m.default || m)),
  guest: defineAsyncComponent(() => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/layouts/guest.vue").then(m => m.default || m)),
  home: defineAsyncComponent(() => import("/opt/render/project/src/apps/ui/layers/dashboard/layouts/home.vue").then(m => m.default || m)),
  onboarding: defineAsyncComponent(() => import("/opt/render/project/src/apps/ui/layers/onboarding/layouts/onboarding.vue").then(m => m.default || m)),
  "public-share-token": defineAsyncComponent(() => import("/opt/render/project/src/apps/ui/layers/token-designer/layouts/public-share-token.vue").then(m => m.default || m)),
  "token-designer": defineAsyncComponent(() => import("/opt/render/project/src/apps/ui/layers/token-designer/layouts/token-designer.vue").then(m => m.default || m)),
  tokenomics: defineAsyncComponent(() => import("/opt/render/project/src/apps/ui/layers/token-designer/layouts/tokenomics.vue").then(m => m.default || m)),
  "automated-market-making": defineAsyncComponent(() => import("/opt/render/project/src/apps/ui/layers/tools-and-services/layouts/automated-market-making.vue").then(m => m.default || m)),
  "engage-a-market-maker": defineAsyncComponent(() => import("/opt/render/project/src/apps/ui/layers/tools-and-services/layouts/engage-a-market-maker.vue").then(m => m.default || m)),
  "exchange-listing-side-menu": defineAsyncComponent(() => import("/opt/render/project/src/apps/ui/layers/tools-and-services/layouts/exchange-listing-side-menu.vue").then(m => m.default || m)),
  "market-maker-monitoring": defineAsyncComponent(() => import("/opt/render/project/src/apps/ui/layers/market-maker-monitoring/layouts/market-maker-monitoring.vue").then(m => m.default || m))
}