import type { paths as Paths } from '@forgd/contract/openapi'
import { useDevLogger } from '#core/composables/useDevLogger'
import { $me } from '#me'
import { useStorage } from '@vueuse/core'

export type Me = Paths['/users/me']['get']['responses']['200']['content']['application/json']
export type LiquidityMe = Paths['/liquidity/me']['get']['responses']['200']['content']['application/json']
export type Organization = Omit<Me['organizations'][number], 'projects'>
export type Projects = Me['organizations'][number]['projects']
export type Project = Me['organizations'][number]['projects'][number]

type OrganizationWithProjects = Organization & { projects: Project[] }
type AuthMe = Me | LiquidityMe

interface AuthSwrCache {
  me: AuthMe
  projectId?: string
}

const disallowedRedirects = ['/login', '/verify-email']

function _isTokenMember(
  me: AuthMe | null,
): me is Me {
  if (!me) {
    return false
  }
  return 'organizations' in me
}

function _isMarketMaker(
  me: AuthMe | null,
): me is LiquidityMe {
  if (!me) {
    return false
  }
  return 'marketMakerId' in me
}

export const useAuth = defineStore('auth', () => {
  const logger = useDevLogger('auth')
  const route = useRoute()
  const supabase = useSupabase()
  const tracer = useTracer()

  const loggedIn = ref<boolean | null>(null)
  const me = ref<Me | null>(null)
  const liquidityMe = ref<LiquidityMe | null>(null)

  const isTokenMember = computed(() => _isTokenMember(me.value))
  const isMarketMaker = computed(() => _isMarketMaker(me.value))
  const organizations = ref<OrganizationWithProjects[] | null>(null)
  const organization = ref<OrganizationWithProjects | null>(null)
  const project = ref<Project | null>(null)
  const pending = ref(false)
  const ticker = computed(() => project.value?.ticker?.toUpperCase())
  const accessTokenCookie = useCookie('forgd-access-token')
  const authSwrCache = useStorage<AuthSwrCache>('forgd:auth:cache', null, import.meta.client ? window.localStorage : undefined, {
    listenToStorageChanges: true,
    serializer: {
      read: (v: any) => v === null ? null : JSON.parse(v),
      write: (v: any) => JSON.stringify(v),
    },
  })

  const logToConsole = useStorage<boolean>('forgd:auth:logging', null)
  function log(...args: any[]) {
    if (import.meta.dev || logToConsole.value || me.value?.email.includes('@forgd.com')) {
      // eslint-disable-next-line no-console
      console.log('[auth]', ...args)
    }
  }

  /**
   * Listen to Supabase token refreshes and update the access token cookie
   */
  supabase.auth.onAuthStateChange((event, session) => {
    if (event === 'TOKEN_REFRESHED') {
      logger.debug('Supabase token refreshed')
      accessTokenCookie.value = session?.access_token
    }
  })

  /**
   * Helpers for authentication redirects
   * TODO: something like `redirectAuthenticatedHome` from https://github.com/forged-com/forgd/pull/1489
   */
  const dashboardPath = useRuntimeConfig().public.featureFlags.dashboard.path
  const onboardingPath = useRuntimeConfig().public.featureFlags.onboarding.path

  // watch for auth state changes to sync tabs
  watch(authSwrCache, (val) => {
    if (route.path === '/verify-email') {
      return
    }
    if (!val) {
      if (loggedIn.value) {
        logout()
      }
    }
    else if (!loggedIn.value) {
      window.location.href = dashboardPath
    }
  })
  const isOrganizationOwner = computed(() => organization.value?.ownerUserId === me.value?.id)

  const shouldAutoLogin = ref(true)
  // if this exists then we're authenticating
  const authCheckPromise: Ref<Promise<boolean> | null> = ref(null)

  async function doAuthFetch() {
    pending.value = true
    // TODO probably a util for this
    let mePayload: Me | null = null
    try {
      // @ts-expect-error untyped
      mePayload = await $me().finally(() => {
        pending.value = false
      })
    }
    catch (e: any) {
      console.error(e)
      if (Number(e.statusCode) !== 200) {
        if (route.path !== '/login') {
          window.location.href = '/login?action=logout'
        }
      }
      return false
    }
    if (!mePayload) {
      return false
    }

    loggedIn.value = true
    me.value = mePayload

    // this logic only applies to /users/me
    if (_isTokenMember(mePayload)) {
      organizations.value = (me.value.organizations || []) as any as OrganizationWithProjects[]

      let _project = project.value
      let _organization = organization.value
      // we have hydrated project and organization from the cache HOWEVER they may not match the new payload
      // so we select the org and project IF they match, otherwise fallback to the first available active project
      if (_project || _organization) {
        let matched = false
        for (const org of mePayload.organizations || []) {
          for (const p of org.projects || []) {
            if (p.id === _project?.id && org.id === _organization?.id) {
              logger.debug('selected project from auth cache', {
                organization: org.name,
                project: p.name,
              })
              _project = p
              _organization = org
              matched = true
            }
          }
        }
        if (!matched) {
          _project = null
          _organization = null
        }
      }

      // avoid using any stale data for hydration
      // and if the user is a project owner, default to that project
      const activeOrganizations = organizations.value?.filter(org => org.memberStatus === 'active' && org.projects.length > 0)
      const ownedActiveOrganization = activeOrganizations?.find(org => org.ownerUserId === me.value?.id)

      organization.value = _organization || ownedActiveOrganization || activeOrganizations?.[0] || organizations.value?.[0]

      // if we don't have a selected project, infer it from the me payload
      project.value = _project || inferSelectedProject(mePayload)
    }
    else {
      liquidityMe.value = mePayload
    }

    authSwrCache.value = {
      me: me.value,
      projectId: project.value?.id,
    }
    return true
  }

  async function refresh(options: { from?: string }) {
    logger.debug('refresh', options)
    await doAuthFetch()
  }

  // opt-in swr hydration of auth payload
  async function check(options?: { from?: string, swr?: boolean, onFailure?: () => Promise<void> | void, redirect?: boolean }) {
    logger.debug('check', options)
    // avoid multiple auth checks running at once
    if (authCheckPromise.value) {
      logger.debug('authCheckPromise already exists')
      return authCheckPromise.value
    }
    return authCheckPromise.value = new Promise<boolean>((resolve) => {
      // we apply SWR logic to authentication
      if (options?.swr && authSwrCache.value) {
        logger.debug('hydrating from cache')
        // hydrate from payload
        const { me: _me, projectId } = authSwrCache.value
        if (_isTokenMember(_me)) {
          me.value = _me
          organizations.value = _me.organizations
          const _project = _me.organizations?.flatMap(o => o.projects)?.find(p => p.id === projectId)
          organization.value = organizations.value?.find(o => o.id === _project?.organizationId) || null
          project.value = _project || null
          logger.debug('hydrated from cache', {
            type: 'tokenMember',
            organization: organization.value?.name,
            project: project.value?.name,
          })
        }
        else {
          liquidityMe.value = _me
          logger.debug('hydrated from cache', {
            type: 'liquidityMember',
          })
        }
        loggedIn.value = true
        // do the auth check async, don't block the user
        doAuthFetch().then(async (res) => {
          !res && options?.onFailure?.()
          if (options?.redirect) {
            await redirect({ from: options.from })
          }
        })
        return resolve(true)
      }
      doAuthFetch().then(async (res) => {
        !res && await options?.onFailure?.()
        if (options?.redirect) {
          await redirect({ from: options.from })
        }
        resolve(res)
      })
    }).finally(() => {
      authCheckPromise.value = null
      if (me.value) {
        tracer.identify(me.value?.id, { email: me.value?.email })
      }
    })
  }

  /**
   * Note: this needs to be called in addition to the Supabase signOut function
   */
  function clear() {
    loggedIn.value = false
    // full clean up of args to avoid stale data when switching accounts
    me.value = null
    liquidityMe.value = null
    project.value = null
    organization.value = null
    organizations.value = null
    shouldAutoLogin.value = false
    authSwrCache.value = null
    accessTokenCookie.value = null
    refreshCookie('forgd-access-token')
  }

  async function switchProject(newProject: Project) {
    logger.debug('switchProject', {
      from: project.value?.name,
      to: newProject.name,
    })
    project.value = newProject
    authSwrCache.value = {
      me: me.value!,
      projectId: project.value?.id,
    }
    // TODO hacky way to clear the state while we don't handle reactivity globally yet
    await nextTick(() => {
      window.location.reload()
    })
  }

  // We need to do this to avoid any reactive updates for the page the user is on,
  // this means we should be able to guarantee the user exists in an authenticated page
  async function logout() {
    // Logout is a multi-step process:
    // 1. sign out via supabase, this can take a second so we keep the loading state
    // 2. navigate to the login page with a special query param to indicate logout
    // 3. on the login page we check for this param and clear the persisted state
    await supabase.auth.signOut()
      .then(() => {
        // an external navigation will clear some state but not the data in localStorage
        // so we need to give the login page a hint to clear the localStorage data
        navigateTo('/login?action=logout', { external: true })
      })
  }

  async function redirect(options: { from?: string }) {
    logger.debug('redirect', options)

    if (!loggedIn.value) {
      logger.debug('redirecting to login')
      // unsure why external is needed here but it is
      await navigateTo('/login', { external: true })
      return
    }

    // liquidity.forgd.com
    if (me.value && _isMarketMaker(me.value)) {
      await navigateTo(redirectTo.value)
      redirectTo.value = null
      return
    }

    // app.forgd.com
    if (me.value?.organizations?.length) {
      const state = inferOnboardingState(me.value)

      if (state.scenario === 2) {
        logger.debug('redirecting to', redirectTo.value || state.redirectTo)
        await navigateTo(redirectTo.value || state.redirectTo)
        redirectTo.value = null
      }
      else {
        logger.debug('redirecting to onboarding', state.scenario)
        await navigateTo(state.redirectTo)
        redirectTo.value = null
      }
    }
  }

  function setRedirectTo(path: string) {
    if (disallowedRedirects.includes(path)) {
      logger.debug('disallowed post-auth redirect', path)
      return
    }
    logger.debug('setting post-auth redirect', path)
    redirectTo.value = path
  }

  const redirectTo = useStorage<string | null>('forgd:auth:redirect', null)

  return {
    organization,
    organizations,
    check,
    switchProject,
    loggedIn,
    me,
    liquidityMe,
    isOrganizationOwner,
    project,
    shouldAutoLogin,
    ticker,
    redirectTo,
    setRedirectTo,
    clear,
    logout,
    pending,
    dashboardPath,
    onboardingPath,
    refresh,
    redirect,
    log,
    isMarketMaker,
    isTokenMember,
  }
})
