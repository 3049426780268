import type { NavLink } from '#core/utils/nav'
import type { ParsedContentFile } from '@nuxt/content'

export function firstChildToIfDir(item: ParsedContentFile) {
  if (!item.page && item.children?.[0]) {
    return item.children[0]?.path
  }
  return item.path
}

let academyNavPromise: Promise<AcademyNavGroup[]> | null = null
const academyNav = ref<AcademyNavGroup[]>([])

type MergedNav = ParsedContentFile & NavLink
export type AcademyContentFile = MergedNav & {
  wistiaId?: string
  body: {
    value: any[]
  }
}
export interface AcademyNavGroup extends AcademyContentFile {
  children?: AcademyContentFile[]
}
export function fetchAcademyNav() {
  academyNavPromise = academyNavPromise || new Promise((resolve) => {
    queryCollectionNavigation('pages', ['icon']).then((res) => {
      academyNav.value = res[0].children as AcademyNavGroup[]
      // @ts-expect-error type hack
      resolve({ data: academyNav })
    })
  })
  return Object.assign(academyNavPromise, { data: academyNav }) as any as ({ data: Ref<AcademyNavGroup[]> } & Promise<{ data: Ref<AcademyNavGroup[]> }>)
}

export const forgdAcademyNav = computed(() => {
  if (!academyNav.value?.length) {
    fetchAcademyNav() // trigger nav load
    return []
  }
  return [
    ...academyNav.value.map((item) => {
      return {
        ...item,
        label: item.title,
        to: item.path,
        children: (item.children || []).map(item => ({ ...item, label: item.title, to: firstChildToIfDir(item) })),
      }
    }),
    {
      label: 'Other Readings',
      icon: 'i-heroicons-newspaper',
      children: [
        { label: 'Forgd Products FAQs', to: 'https://forgd.com/faq#tools' },
        { label: 'About Forgd FAQs', to: 'https://forgd.com/faq#about' },
        { label: 'Forgd Blog', to: 'https://forgd.com/blog' },
      ],
    },
  ] satisfies NavLink[]
})
